import { useEffect, useState } from "react"
import { Controller, Navigation } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css/bundle"

import * as S from "./styled"
import Container from "../Container"
import { Icon } from "../../assets/icons"
import AnimationFrame from "../AnimationFrame"
import { animations } from "../../assets/animations"

type Props = {
  images: string[]
  loading: boolean
}

const Slider = ({ images, loading }: Props) => {
  const [sSwiper, setSSwiper] = useState<any>(null)

  const [firstSwiper, setFirstSwiper] = useState<any>(null)
  const [secondSwiper, setSecondSwiper] = useState<any>(null)
  const [list, setList] = useState<string[]>(["", ""])

  useEffect(() => {
    if (images.length > 0) setList(images)
  }, [images])

  const renderBigSlider = () => {
    const els: any[] = []

    for (let i = 0; i < list.length - 1; i++) {
      els.push(
        <SwiperSlide key={i}>
          <S.BiggerSlide className="coverSlideItem">
            <img src={list[i]} alt="" loading="lazy" />
          </S.BiggerSlide>
        </SwiperSlide>
      )
    }

    return els
  }

  const renderSmallSlider = () => {
    const els: any[] = []

    for (let i = 0; i < list.length - 1; i++) {
      els.push(
        <SwiperSlide
          key={i}
          onClick={() => {
            sSwiper.slideTo(i)
          }}
        >
          <S.SmallerSlide>
            <img src={list[i]} alt="" loading="lazy" />
          </S.SmallerSlide>
        </SwiperSlide>
      )
    }

    return els
  }

  return (
    <S.SliderContainer>
      {loading && <AnimationFrame data={animations.loading} />}

      <Swiper
        id="bigSlider"
        modules={[Controller, Navigation]}
        controller={{ control: firstSwiper }}
        slidesPerView={1}
        onInit={(e: any) => {
          setSSwiper(e)
        }}
        onSwiper={setSecondSwiper}
        direction="horizontal"
        navigation={{
          prevEl: ".big-slider-control-back",
          nextEl: ".big-slider-control-next",
        }}
        draggable={true}
        loop={true}
        lazyPreloadPrevNext={4}
        style={{
          position: "unset",
        }}
      >
        {renderBigSlider()}

        {window.innerWidth < 720 && (
          <S.Controller
            $type={"back"}
            className="big-slider-control-back"
            $fromBig={true}
          >
            <Icon.SlideControll />
          </S.Controller>
        )}
        {window.innerWidth < 720 && (
          <S.Controller
            $type={"next"}
            className="big-slider-control-next"
            $fromBig={true}
          >
            <Icon.SlideControll />
          </S.Controller>
        )}
      </Swiper>

      <Container>
        <Swiper
          id="smallSlider"
          modules={[Controller, Navigation]}
          controller={{ control: secondSwiper }}
          spaceBetween={30}
          slidesPerView={
            window.innerWidth > 720 ? 4 : window.innerWidth > 540 ? 3 : 2
          }
          onSwiper={(e: any) => {
            setFirstSwiper(e)
          }}
          direction="horizontal"
          navigation={{
            prevEl: ".slider-control-back",
            nextEl: ".slider-control-next",
          }}
          draggable={true}
          loop={list.length > 4}
          style={{
            opacity: +!loading,
          }}
        >
          {renderSmallSlider()}

          <S.Controller $type={"back"} className="slider-control-back">
            <Icon.SlideControll />
          </S.Controller>

          <S.Controller $type={"next"} className="slider-control-next">
            <Icon.SlideControll />
          </S.Controller>
        </Swiper>
      </Container>
    </S.SliderContainer>
  )
}

export default Slider
