import { Link } from "react-router-dom"
import Container from "../Container"
import * as S from "./styled"
import { consts } from "../../utils/consts"
import { Icon } from "../../assets/icons"

const BestPlace = () => {
  return (
    <S.Section>
      <Container>
        <S.Content>
          <S.SectionHeader>
            <S.SectionTitle>ENCONTRE O MELHOR LUGAR</S.SectionTitle>
            <S.SectionDescription>
              Converse conosco e agende uma visita. Você ficará surpreso com o
              que temos a oferecer!
            </S.SectionDescription>
          </S.SectionHeader>
          <Link to={`${consts.whatsapp}Olá!`} target="_blank">
            <Icon.Whatsapp />
            <S.Text>Fale conosco</S.Text>
          </Link>
        </S.Content>
      </Container>
    </S.Section>
  )
}

export default BestPlace
