import Hero from "./files/hero.png"
import HeroBlue from "./files/hero_blue.png"
import Location from "./files/example/location.png"
import LogoBlue from "./files/logo_blue.png"
import LogoHeader from "./files/logo_header.png"

// Highlight
import image1 from "./files/highlights/image1.png"
import image2 from "./files/highlights/image2.png"
import image3 from "./files/highlights/image3.png"
import image4 from "./files/highlights/image4.png"
import image5 from "./files/highlights/image5.png"

// Cards
import card1 from "./files/cards/image1.jpeg"
import card2 from "./files/cards/image2.jpeg"
import card3 from "./files/cards/image3.jpeg"
import card4 from "./files/cards/image4.jpeg"
import card5 from "./files/cards/image5.jpeg"

export const Image = {
  Hero,
  HeroBlue,
  Location,
  LogoBlue,
  LogoHeader,
  HighLights: {
    image1,
    image2,
    image3,
    image4,
    image5,
  },
  Cards: {
    card1,
    card2,
    card3,
    card4,
    card5,
  },
}
