import { Link } from "react-router-dom"
import { Icon } from "../../assets/icons"
import { Image } from "../../assets/images"
import Container from "../Container"
import SocialBtn from "../SocialBtn"
import * as S from "./styled"

const Footer = () => {
  const scrollTop = () => {
    window.scroll({ top: 0, behavior: "smooth" })
  }

  return (
    <S.Section>
      <Container>
        <S.Content>
          <S.Company>
            <img src={Image.LogoBlue} alt={""} />
            <S.Social>
              <SocialBtn
                size={"big"}
                icon={<Icon.Instagram />}
                link={"https://www.instagram.com/lavecci_imoveis/"}
              />
            </S.Social>
            <S.Register>CRECI/SC 8846j</S.Register>
            <S.PoweredBy>
              <span>Desenvolvido por </span>
              <Link to={"https://statustech.app"} target="_blank">
                Statustech
              </Link>
            </S.PoweredBy>
          </S.Company>
          <S.Columns>
            <span />
            <S.Column>
              <S.ColTitle>Imóveis</S.ColTitle>
              <Link to={"/search?purpose=1"} onClick={scrollTop}>
                Alugar
              </Link>
              <Link to={"/search?purpose=2"} onClick={scrollTop}>
                Comprar
              </Link>
            </S.Column>
            <S.Column>
              <S.ColTitle>Contato</S.ColTitle>
              <Link to={"mailto:contato@lavecci.com.br"}>
                contato@lavecci.com.br
              </Link>
              <Link to={"tel:+5548999733487"}>(48) 99973.3487</Link>
              <span>Florianópolis - SC</span>
            </S.Column>
          </S.Columns>
        </S.Content>
      </Container>
    </S.Section>
  )
}

export default Footer
