import { useCallback, useEffect, useState } from "react"

import * as S from "./styled"
import Container from "../../components/Container"
import Header from "../../components/Header"
import SearchBox from "../../components/SearchBox"
import Button from "../../components/Button"
import BestPlace from "../../components/BestPlace"
import Footer from "../../components/Footer"
import SocialBtn from "../../components/SocialBtn"
import { Icon } from "../../assets/icons"
import { Link, useNavigate, useParams } from "react-router-dom"
import Api from "../../api"
import { TProperty } from "../../utils/@types/property"
import Slider from "../../components/Slider"
import { formatMoney } from "../../utils/formatMoney"
import Card from "../../components/Card"
import { consts } from "../../utils/consts"
import { formatArea } from "../../utils/formatArea"

const Details = () => {
  const navigate = useNavigate()

  const { propertyId } = useParams()
  const [loading, setLoading] = useState(true)
  const [property, setProperty] = useState<TProperty | null>(null)
  const [simmilars, setSimmillars] = useState<TProperty[]>([])
  const [page, setPage] = useState(1)

  const loadSimmillars = async () => {
    if (property) {
      try {
        Api.get
          .simmilars(
            property.location.city.cod as string,
            property.type,
            property.id
          )
          .then((data: any) => {
            setSimmillars(data)
          })
      } catch (error) {
        navigate(-1)
      }
    }
  }

  const loadData = useCallback(() => {
    if (!propertyId) {
      navigate(-1)
    } else {
      try {
        Api.get.property(propertyId).then((data: any) => {
          setProperty(data)
          loadSimmillars().then(() => {
            setLoading(false)
          })
        })
      } catch (error) {
        navigate(-1)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, propertyId])

  useEffect(() => {
    setProperty(null)
    window.scrollTo({ top: 0 })
    loadData()
  }, [loadData])

  const compose = {
    // street + number + neighborhood

    address: () => {
      let str = property?.location.street ?? ""

      if (property?.location.number && str.length > 0)
        str += ` nº ${property?.location.number}`
      if (property?.location.neighborhood) {
        if (str.length > 0) str += ", "
        str += property?.location.neighborhood
      }

      return str
    },
    cityState: () => {
      const str = `${property?.location.city.name} - ${property?.location.state.cod}`

      return str
    },
  }

  useEffect(() => {
    console.log(property?.description)
  }, [property])

  return (
    <S.Page>
      <S.Hero>
        <Container direction={"column"}>
          <Header />
        </Container>
      </S.Hero>

      <Slider images={property?.images.all ?? []} loading={loading} />

      <Container>
        <S.Section $pv={window.innerWidth > 720 ? 48 : 80}>
          <S.ResumeBlock>
            <S.ResumeTitle>{property?.title ?? ""}</S.ResumeTitle>
            <S.Location>
              <S.LocationStreet>
                {property && compose.address()}
              </S.LocationStreet>
              <S.LocationCity>{property && compose.cityState()}</S.LocationCity>
            </S.Location>
            <S.Actions>
              <SocialBtn
                icon={<Icon.Share />}
                link=""
                fn={() => {
                  if (window.navigator.share) {
                    window.navigator.share({
                      title: "Compartilhar",
                      text: property?.title ?? "Confira este imóvel",
                      url: `${window.location.origin}/details/${property?.id}`,
                    })
                  } else {
                    window.navigator.clipboard.writeText(
                      `${window.location.origin}/details/${property?.id}`
                    )
                    alert("Link copiado com sucesso")
                  }
                }}
              />
            </S.Actions>
          </S.ResumeBlock>
          <S.Description>
            <S.Info>
              <S.DescTexts>
                {property?.description.split("\n").map((prg, k) => (
                  <S.DescText key={k}>{prg}</S.DescText>
                ))}
              </S.DescTexts>
              <S.Explore>
                {property && (
                  <S.SubInfos>
                    {property.bedrooms > 0 && (
                      <S.SInfo>
                        <S.SIValue>{property?.bedrooms ?? 0}</S.SIValue>
                        <S.SILabel>Quartos</S.SILabel>
                      </S.SInfo>
                    )}
                    {property.bathrooms > 0 && (
                      <S.SInfo>
                        <S.SIValue>{property?.bathrooms ?? 0}</S.SIValue>
                        <S.SILabel>Banheiros</S.SILabel>
                      </S.SInfo>
                    )}
                    {property.parkingSpaces > 0 && (
                      <S.SInfo>
                        <S.SIValue>{property?.parkingSpaces ?? 0}</S.SIValue>
                        <S.SILabel>Vagas</S.SILabel>
                      </S.SInfo>
                    )}
                    {property.area > 0 && (
                      <S.SInfo>
                        <S.SIValue>{formatArea(property?.area ?? 0)}</S.SIValue>
                        <S.SILabel>Area</S.SILabel>
                      </S.SInfo>
                    )}
                  </S.SubInfos>
                )}

                <S.Prices>
                  <S.Price>
                    {property && (
                      <span>
                        {property.price.main !== 0
                          ? formatMoney(property.price.main ?? 0)
                          : "Preço a combinar"}
                      </span>
                    )}
                  </S.Price>
                  {property?.price &&
                    typeof property.price !== "number" &&
                    property.price.condominium !== undefined &&
                    property.price.condominium !== 0 && (
                      <S.SubPrice>
                        <span>Condomínio:</span>
                        <span>
                          {` ${formatMoney(property.price.condominium, true)}`}
                        </span>
                      </S.SubPrice>
                    )}
                  {property?.price &&
                    typeof property.price !== "number" &&
                    property.price.tax !== undefined &&
                    property.price.tax !== 0 && (
                      <S.SubPrice>
                        <span>IPTU:</span>
                        <span>{` ${formatMoney(
                          property.price.tax,
                          true
                        )}`}</span>
                      </S.SubPrice>
                    )}
                </S.Prices>
              </S.Explore>
            </S.Info>
            <Link to={property?.coordLink as string} target="_blank">
              <img src={property?.mapImage} alt={""} />
              <span>Ver local</span>
              <Icon.Dropdown />
            </Link>
          </S.Description>
          <Link
            to={`${consts.whatsapp}Olá! Gostaria de mais informações sobre o imóvel:

            ${window.location.href}`}
            target="_blank"
            onClick={(e) => {
              if (window.navigator.share) {
                e.preventDefault()

                window.navigator.share({
                  title: "Compartilhar",
                  text: `Olá! Gostaria de mais informações sobre o imóvel:
                  
                  ${window.location.href}`,
                  url: `${window.location.href}`,
                })
              }
            }}
          >
            <Icon.Whatsapp />
            <S.Text>Quero mais informações</S.Text>
          </Link>
        </S.Section>
      </Container>

      {simmilars.length > 0 && (
        <Container>
          <S.Section
            $pv={
              window.innerWidth > 720 ? 130 : window.innerWidth <= 540 ? 60 : 30
            }
          >
            <S.SectionHeader>
              <S.SectionTitle>IMÓVEIS SEMELHANTES</S.SectionTitle>
              <S.SectionDescription>
                Veja outros imóveis semelhantes
              </S.SectionDescription>
            </S.SectionHeader>
            <S.Simmilars>
              {simmilars.slice(0, page * 3).map((prop, k) => (
                <Card key={k} data={prop} />
              ))}
            </S.Simmilars>
            {simmilars.length - page * 3 > 0 && (
              <Button
                type="default"
                text={"Mostrar mais"}
                fn={() => {
                  setPage(page + 1)
                }}
              />
            )}
          </S.Section>
        </Container>
      )}

      <Container pb={120}>
        <S.Section $pv={40}>
          <S.SectionHeader>
            <S.SectionTitle>REALIZAR NOVA BUSCA</S.SectionTitle>
          </S.SectionHeader>
        </S.Section>
        <SearchBox normal={true} />
      </Container>

      <BestPlace />
      <Footer />
    </S.Page>
  )
}

export default Details
