import styled from "styled-components"
import { Image } from "../../assets/images"

export const Page = styled.div``

export const Hero = styled.div`
  background-image: url("${Image.HeroBlue}");
  background-size: 115vw;
  background-position: 0 -60px;
  height: calc(100vh - 140px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpoints.big}px) {
    background-size: cover;
    background-position: center -60px;
  }
`

export const HeroContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translateY(-70px);
`

export const HeroTexts = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  color: ${({ theme }) => theme.colors._primary.white};
`

export const HeroTitle = styled.h1`
  font-family: Bona Nova;
  font-weight: 400;
  font-size: 52px;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.big}px) {
    font-size: 48px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    font-size: 40px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.small}px) {
    font-size: 32px;
  }
`

export const HeroDescription = styled.span`
  max-width: 700px;
  font-size: 20px;
  font-weight: 400;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.big}px) {
    font-size: 18px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    font-size: 16px;
  }
`

// Section

export const Section = styled.div<{ $bg?: string; $pv?: number }>`
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  padding: ${({ $pv }) => $pv ?? 140}px 0;
  display: flex;
  flex-direction: column;
  gap: 48px;
  background-color: ${({ $bg }) => $bg ?? "transparent"};

  button {
    margin: auto;
  }
`

export const SectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    gap: 14px;
  }
`

export const SectionTitle = styled.h2`
  font-family: Bona Nova;
  font-size: 40px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.blue[600]};
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.big}px) {
    font-size: 36px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    font-size: 28px;
  }
`

export const SectionDescription = styled.span`
  text-align: center;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.grey[500]};
  max-width: 700px;

  @media (max-width: ${({ theme }) => theme.breakpoints.big}px) {
    font-size: 18px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    font-size: 16px;
    max-width: 480px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.small}px) {
    max-width: 70vw;
  }
`

// Modalities

export const Modalities = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
`

export const Modality = styled.div`
  max-width: 320px;
  flex: 1;
  padding: 24px;
  background-color: ${({ theme }) => theme.colors._primary.white};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  cursor: pointer;
  transition: transform 0.3s;

  &:hover {
    transform: translateZ(10px) scale(1.04);
  }

  svg {
    width: 60px;
  }

  a {
    text-decoration: none;
    font-size: 16px;
    color: ${({ theme }) => theme.colors._primary.blue};
  }

  a:hover {
    text-decoration: underline;
  }

  @media (max-width: 980px) {
    min-width: calc(50% - 12px);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.small}px) {
    min-width: 100%;
  }
`

export const ModalityName = styled.span`
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.grey[700]};
  white-space: nowrap;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.breakpoints.big}px) {
    font-size: 20px;
  }

  @media (max-width: 980px) {
    white-space: unset;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    font-size: 18px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.small}px) {
    white-space: unset;
  }
`

export const ModalityDescription = styled.span`
  margin-top: -16px;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.grey[400]};
  text-align: left;
  flex: 1;
`

// Recents

export const Recents = styled.div`
  flex: 1;
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: ${({ theme }) => theme.breakpoints.big}px) {
    gap: 24px;
  }

  @media (max-width: 840px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 580px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 54px;
  }
`
