import styled from "styled-components"

export const Wrapper = styled.div<{ $bg?: string }>`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  background-color: ${({ $bg }) => $bg ?? "transparent"};
`

export const Area = styled.div`
  flex: 1;
  display: grid;
  gap: 24px;
`

export const Item = styled.div`
  overflow: hidden;
  cursor: pointer;
  border-radius: 8px;
  max-height: 240px;
  transition: transform 0.3s;

  &:hover {
    transform: translateZ(5px) scale(1.04);
    z-index: 2;
  }

  a {
    width: 100%;
    height: 100%;
    text-decoration: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      img {
        transform: scale(1.05);
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.big}px) {
    max-height: 180px;
  }

  &:nth-child(1) {
    grid-column: 1/2;
  }

  &:nth-child(2) {
    grid-column: 2/3;
  }

  &:nth-child(3) {
    grid-column: 3/4;
  }

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3) {
    grid-row: 1;
  }

  &:nth-child(4) {
    grid-column: 1/3;
  }

  &:nth-child(5) {
    grid-column: 3/4;
  }

  &:nth-child(4),
  &:nth-child(5) {
    grid-row: 2;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.small}px) {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      grid-column: 1/3;
      grid-row: unset;
    }
  }
`

export const ItemImage = styled.img`
  min-width: 100%;
  height: auto;
  min-height: 100%;
  transition: all 0.3s;
`

export const ItemData = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.colors.grey[900]},
    transparent
  );
  transition: opacity 0.3s;
`

export const ItemMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: ${({ theme }) => theme.colors._primary.white};
`

export const ItemLocation = styled.h4`
  white-space: nowrap;
  font-size: 24px;
  font-weight: 600;
`

export const ItemPurpose = styled.span`
  font-size: 16px;
  font-weight: 400;
`
