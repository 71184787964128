import * as S from "./styled"
import Container from "../../components/Container"
import Header from "../../components/Header"
import SearchBox from "../../components/SearchBox"
import Highlights from "../../components/Highlights"
import { Icon } from "../../assets/icons"
import { Link } from "react-router-dom"
import Card from "../../components/Card"
import Button from "../../components/Button"
import BestPlace from "../../components/BestPlace"
import Footer from "../../components/Footer"
import { useEffect, useRef, useState } from "react"
import Api from "../../api"
import { TProperty } from "../../utils/@types/property"
import { consts } from "../../utils/consts"

type ModalityProps = {
  icon: JSX.Element
  name: string
  description: string
  link: string
  blank?: boolean
}

const Modality = ({ icon, name, description, link, blank }: ModalityProps) => {
  const linkRef = useRef<HTMLAnchorElement>(null)

  return (
    <S.Modality
      onClick={() => {
        linkRef?.current?.click()
      }}
    >
      {icon}
      <S.ModalityName>{name}</S.ModalityName>
      <S.ModalityDescription>{description}</S.ModalityDescription>
      <Link
        ref={linkRef}
        to={blank ? `${consts.whatsapp}Olá!` : link}
        onClick={() => {
          window.scroll({ top: 0, behavior: "smooth" })
        }}
        target={blank ? "_blank" : undefined}
      >
        {blank ? "Entrar em contato" : "Ver imóveis"}
      </Link>
    </S.Modality>
  )
}

const Home = () => {
  const [highLights, setHighLights] = useState<TProperty[]>([])
  const [recents, setRecents] = useState<TProperty[]>([])
  const [page, setPage] = useState(1)

  const getData = async () => {
    const docs = await Api.get.highlights()
    const rec = await Api.get.recents()
    setHighLights(docs)
    setRecents(rec)
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <S.Page>
      <S.Hero>
        <Container direction={"column"}>
          <Header />
          <S.HeroContent>
            <S.HeroTexts>
              <S.HeroTitle>VOCÊ FELIZ COM SEU IMÓVEL!</S.HeroTitle>
              <S.HeroDescription>
                Encontrar o imóvel dos seus sonhos é a nossa missão. Com
                conforto, tranqulidade e segurança, temos as melhores ofertas
                para sua busca.
              </S.HeroDescription>
            </S.HeroTexts>
          </S.HeroContent>
        </Container>
      </S.Hero>
      <Container>
        <SearchBox />
      </Container>

      <Container>
        <S.Section>
          <S.SectionHeader>
            <S.SectionTitle>IMÓVEIS EM DESTAQUE</S.SectionTitle>
            <S.SectionDescription>
              Separamos para você alguns dos nossos imóveis em destaque.
            </S.SectionDescription>
          </S.SectionHeader>
          <Highlights list={highLights} />
        </S.Section>
      </Container>

      <Container bg={"#EDEFF6"}>
        <S.Section $pv={100}>
          <S.SectionHeader>
            <S.SectionTitle>VÁRIAS MODALIDADES</S.SectionTitle>
            <S.SectionDescription>
              Nós oferecemos várias modalidades de contrato para que você tenha
              as melhores opções de imóveis disponíveis em nossa base de
              anunciantes.
            </S.SectionDescription>
          </S.SectionHeader>
          <S.Modalities>
            <Modality
              icon={<Icon.House />}
              name="Imóveis urbanos"
              description="Você aluga o imóvel e paga mensalmente o valor"
              link="/search?category=urban"
            />
            <Modality
              icon={<Icon.HouseFarm />}
              name="Imóveis rurais"
              description="Adquira o imóvel de acordo com as condições do vendedor"
              link="/search?category=rural"
            />
            <Modality
              icon={<Icon.House />}
              name="Anunciar"
              description="Anuncie seu imóvel conosco e decida a modalidade de contrato"
              link={`${consts.whatsapp}Olá!`}
              blank={true}
            />
          </S.Modalities>
        </S.Section>
      </Container>

      <Container>
        <S.Section $pv={130}>
          <S.SectionHeader>
            <S.SectionTitle>IMÓVEIS ADICIONADOS RECENTEMENTE</S.SectionTitle>
            <S.SectionDescription>
              A cada dia, aumentamos ainda mais nossa carteira de imóveis
              disponíveis para que você possa encontrar seu lugar perfeito!
            </S.SectionDescription>
          </S.SectionHeader>
          <S.Recents>
            {recents.slice(0, page * consts.perPage).map((prop, k) => (
              <Card key={k} data={prop} />
            ))}
          </S.Recents>
          {recents.length - page * consts.perPage > 0 && (
            <Button
              type="default"
              text={"Mostrar mais"}
              fn={() => {
                setPage(page + 1)
              }}
            />
          )}
        </S.Section>
      </Container>

      <BestPlace />
      <Footer />
    </S.Page>
  )
}

export default Home
