import { ReactComponent as Bath } from "./files/bath.svg"
import { ReactComponent as Bed } from "./files/bed.svg"
import { ReactComponent as Burguer } from "./files/burguer.svg"
import { ReactComponent as Car } from "./files/car.svg"
import { ReactComponent as Dropdown } from "./files/dropdown.svg"
import { ReactComponent as Expand } from "./files/expand.svg"
import { ReactComponent as Facebook } from "./files/facebook.svg"
import { ReactComponent as Heart } from "./files/heart.svg"
import { ReactComponent as House } from "./files/house.svg"
import { ReactComponent as HouseFarm } from "./files/houseFarm.svg"
import { ReactComponent as Instagram } from "./files/instagram.svg"
import { ReactComponent as LinkedIn } from "./files/linkedIn.svg"
import { ReactComponent as Location } from "./files/location.svg"
import { ReactComponent as MailOpen } from "./files/mailOpen.svg"
import { ReactComponent as Phone } from "./files/phone.svg"
import { ReactComponent as Share } from "./files/share.svg"
import { ReactComponent as SlideControll } from "./files/slideControll.svg"
import { ReactComponent as Whatsapp } from "./files/whatsapp.svg"

export const Icon = {
  Bath,
  Bed,
  Burguer,
  Car,
  Dropdown,
  Expand,
  Facebook,
  Heart,
  House,
  HouseFarm,
  Instagram,
  LinkedIn,
  Location,
  MailOpen,
  Phone,
  Share,
  SlideControll,
  Whatsapp,
}
