import styled from "styled-components"

export const Box = styled.div`
  a {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    height: 100%;
  }

  position: relative;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0px 24px 72px 0px rgba(59, 77, 129, 0.1);
  transition: box-shadow 0.3s, transform 0.3s;

  &:hover {
    box-shadow: 0px 30px 72px 0px rgba(59, 77, 129, 0.2);

    transform: translateZ(5px) scale(1.04);
    z-index: 2;

    a img {
      transform: scale(1.1);
    }
  }
`

export const ImageContainer = styled.div`
  overflow: hidden;
  aspect-ratio: 1.75;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  img {
    width: 100%;
    transition: transform 0.3s;
  }
`

export const Tags = styled.div`
  position: absolute;
  display: flex;
  gap: 10px;
  margin: 10px;
  z-index: 3;
  max-width: inherit;
  flex-wrap: wrap;
`

export const Tag = styled.div`
  padding: 4px 12px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.gold.secondary};
  color: ${({ theme }) => theme.colors._primary.white};
  font-size: 12px;
`

export const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 24px;
  background-color: ${({ theme }) => theme.colors._primary.white};

  hr {
    border-color: ${({ theme }) => theme.colors.grey[50]};
    border-width: 1px;
    opacity: 0.5;
    width: 100%;
    margin: 8px 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xxsm}px) {
    padding: 12px;
  }
`

export const Location = styled.span`
  font-size: 22px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.grey[700]};
  text-align: left;
  display: block;
  flex: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.xbig}px) {
    font-size: 18px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    font-size: 16px;
  }
`

export const Price = styled.span`
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.gold.secondary};
  text-align: left;

  @media (max-width: ${({ theme }) => theme.breakpoints.xbig}px) {
    font-size: 16px;
  }
`

export const SubInfos = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.breakpoints.xbig}px) {
    gap: 12px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xbig}px) {
    gap: 0px 12px;
  }
`

export const SInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  color: ${({ theme }) => theme.colors.grey[500]};

  svg {
    width: 24px;
    aspect-ratio: 1;
  }

  span {
    font-size: 16px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xbig}px) {
    svg {
      width: 18px;
    }

    span {
      font-size: 14px;
    }
  }
`

export const CardFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px;
`

export const Role = styled.span`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.grey[500]};

  @media (max-width: ${({ theme }) => theme.breakpoints.xbig}px) {
    font-size: 16px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xxsm}px) {
    font-size: 14px;
  }
`

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
`

export const Action = styled.div`
  display: grid;
  place-items: center;
  padding: 4px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.blue[50]};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.blue[500]};
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.blue[500]};
    color: ${({ theme }) => theme.colors.blue[100]};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xbig}px) {
    svg {
      width: 18px;
      height: 18px;
    }
  }
`
