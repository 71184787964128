import { Link } from "react-router-dom"
import { Icon } from "../../assets/icons"
import { TProperty } from "../../utils/@types/property"
import { formatMoney } from "../../utils/formatMoney"
import relations from "../../utils/relations"
import * as S from "./styled"
import { useRef } from "react"
import { formatArea } from "../../utils/formatArea"

type Props = {
  data: TProperty
  showAddress?: boolean
}

const Card = ({ data, showAddress }: Props) => {
  const shareRef = useRef<HTMLDivElement>(null)

  const compose = {
    address: () => {
      const str =
        (data?.location.street ?? data?.location.neighborhood ?? "") +
        `${data?.location.number ? ` nº ${data?.location.number}` : ""}` +
        `${data?.location.street ?? data?.location.neighborhood ? ", " : ""}${
          data?.location.city.name
        }`

      return str
    },
  }

  const handleClick = (e: any) => {
    if (shareRef.current && shareRef.current.contains(e.target)) {
      e.preventDefault()
    }
  }

  return (
    <S.Box>
      <Link to={`/details/${data.id}`} onClick={handleClick}>
        {/* <S.Tags>
          {data.tags.map((tag, k) => (
            <S.Tag key={k}>{tag}</S.Tag>
          ))}
        </S.Tags> */}
        <S.ImageContainer>
          <img src={data.images.main} alt={""} />
        </S.ImageContainer>
        <S.Main>
          <S.Location>
            {showAddress ? compose.address() : data?.title}
          </S.Location>
          <S.Price>
            {data && data.price.main > 0
              ? formatMoney(data?.price.main)
              : "A combinar"}
          </S.Price>
          <S.SubInfos>
            <S.SInfo>
              <Icon.Bed />
              <span>{data.bedrooms}</span>
            </S.SInfo>
            <S.SInfo>
              <Icon.Bath />
              <span>{data.bathrooms}</span>
            </S.SInfo>
            <S.SInfo>
              <Icon.Car />
              <span>{data.parkingSpaces}</span>
            </S.SInfo>
            <S.SInfo>
              <Icon.Expand />
              <span>{formatArea(data.area)}</span>
            </S.SInfo>
          </S.SubInfos>
          <hr />
          <S.CardFooter>
            <S.Role>
              {
                relations.options.purposes.find((p) => p.key === data.purpose)
                  ?.value
              }
            </S.Role>
            <S.Actions>
              <S.Action
                ref={shareRef}
                onClick={() => {
                  if (window.navigator.share) {
                    window.navigator.share({
                      title: "Compartilhar",
                      text: data.title ?? "Confira este imóvel",
                      url: `${window.location.origin}/details/${data.id}`,
                    })
                  } else {
                    window.navigator.clipboard.writeText(
                      `${window.location.origin}/details/${data.id}`
                    )
                    alert("Link copiado com sucesso")
                  }
                }}
              >
                <Icon.Share />
              </S.Action>
            </S.Actions>
          </S.CardFooter>
        </S.Main>
      </Link>
    </S.Box>
  )
}

export default Card
