import styled from "styled-components"

// Sliders

export const SliderContainer = styled.div`
  position: relative;

  #smallSlider {
    width: calc(100% - 160px);
    max-height: 200px;
    position: unset;
    margin-top: -30px;
    z-index: 3;
    transition: opacity 0.3;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    #bigSlider {
      position: unset;
      width: 100%;
      max-height: 45vh;
      background-color: ${({ theme }) => theme.colors.blue[800]};
    }

    #smallSlider {
      display: none;
    }
  }
`

export const OverInfo = styled.div<{ $loading: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 2;
  height: calc(100% - 170px);
  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.colors.grey[900]},
    transparent
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: ${({ $loading }) => ($loading ? 0 : 1)};
  transition: opacity 0.3s;

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    height: 100%;
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.colors.grey[900]},
      transparent 50% 100%
    );
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.small}px) {
    display: none;
  }
`

export const OverInfoText = styled.span`
  font-family: Bona Nova;
  font-weight: 400;
  font-size: 40px;
  color: ${({ theme }) => theme.colors._primary.white};
  margin-top: -12.5%;

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    margin-top: unset;
    position: absolute;
    bottom: 24px;
    font-size: 32px;
  }
`

export const BiggerSlide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    img {
      width: unset;
      object-fit: cover;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.small}px) {
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`

export const SmallerSlide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: 100%;
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;

  img {
    width: 100%;
    min-height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }
`

export const Controller = styled.div<{
  $type: "back" | "next"
  $fromBig?: boolean
}>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  ${({ $type, $fromBig }) =>
    $type === "back" ? `left: ${$fromBig ? 24 : 0}px;` : ""}
  ${({ $type, $fromBig }) =>
    $type === "next" ? `right: ${$fromBig ? 24 : 0}px;` : ""}
  top: 50%;
  transform: translateY(-50%);
  margin-top: -15px;
  z-index: 4;

  svg {
    width: 100%;
    aspect-ratio: 1;
    ${({ $type }) => ($type === "back" ? "transform: rotate(180deg);" : "")}
  }

  @media (max-width: 1080px) {
    width: 32px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    width: 24px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    margin-top: 0;
  }
`
