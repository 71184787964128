import styled from "styled-components"

export const Btn = styled.div<{ $size?: "big" | "small" }>`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.blue[50]};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.blue[500]};
  transition: background-color 0.3s, color 0.3s;
  border-radius: ${({ $size }) => ($size === "big" ? 4 : 2)}px;
  overflow: hidden;

  a, div {
    display: grid;
    place-items: center;
    text-decoration: none;
    color: currentColor;
    padding: ${({ $size }) => ($size === "big" ? 14 : 4)}px;
    overflow: hidden;

    svg {
      width: ${({ $size }) => ($size === "big" ? 20 : 22)}px;
      height: ${({ $size }) => ($size === "big" ? 20 : 22)}px;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.blue[500]};
    color: ${({ theme }) => theme.colors.blue[100]};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xbig}px) {
    padding: ${({ $size }) => ($size === "big" ? 10 : 6)}px;

    svg {
      width: ${({ $size }) => ($size === "big" ? 20 : 18)}px;
      height: ${({ $size }) => ($size === "big" ? 20 : 18)}px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.big}px) {
    a {
      padding: ${({ $size }) => ($size === "big" ? 4 : 6)}px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.small}px) {
    a {
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
`
