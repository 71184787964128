import styled from "styled-components"

export const Wrapper = styled.div`
  position: relative;
`

export const Area = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
`

export const MainArea = styled.div<{ $showing?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  & > span:nth-child(1) {
    font-weight: 600;
  }

  span {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.blue[700]};
  }

  svg {
    transition: transform .3s;
    transform: rotate(${({ $showing }) => $showing ? 180 : 0}deg);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {

    & > span {
      font-size: 14px;
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }
`

export const Dropdown = styled.div<{ $showing: boolean }>`
  position: absolute;
  display: ${({ $showing }) => ($showing ? "flex" : "none")};
  flex-direction: column;
  top: calc(100% + 4px);
  z-index: 3;
  background-color: ${({ theme }) => theme.colors._primary.white};
  box-shadow: 0 4px 22px -6px rgba(24, 46, 79, 0.2);
  border-radius: 6px;
  min-width: 100%;
`

export const Item = styled.div`
  background-color: ${({ theme }) => theme.colors._primary.white};
  padding: 4px 12px;
  border-radius: 6px;
  cursor: pointer;
`
