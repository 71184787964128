import styled from "styled-components"

export const Section = styled.footer`
  background-color: ${({ theme }) => theme.colors._primary.white};
`

export const Content = styled.div<{ $bg?: string; $pv?: number }>`
  display: flex;
  justify-content: space-between;
  gap: 48px;
  padding: 80px 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    flex-direction: column-reverse;
    gap: 60px;
  }
`

export const Company = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${({ theme }) => theme.breakpoints.big}px) {
    width: 180px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    align-items: center;
    width: 100%;
  }
`

export const PoweredBy = styled.div`
  display: flex;
  font-size: 14px;
  color: ${({ theme }) => theme.colors._primary.grey};
  gap: 4px;

  span,
  a {
    font-family: Public Sans;
    font-size: 16px;
  }

  a {
    text-decoration: none;
    color: currentColor;

    &:hover {
      text-decoration: underline;
    }
  }
`

export const Social = styled.div`
  display: flex;
  margin-top: 28px;
  gap: 16px;
  padding: 12px 0;
  width: fit-content;
`

export const Register = styled.span`
  font-family: Public Sans;
  font-size: 16px;
  color: ${({ theme }) => theme.colors._primary.grey};
`

export const Columns = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  gap: 60px;

  @media (max-width: ${({ theme }) => theme.breakpoints.big}px) {
    gap: 24px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    justify-content: space-around;
    gap: 32px;

    span {
      display: none;
    }
  }

  @media (max-width: 450px) {
    flex-direction: column;
    gap: 48px;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 21px;

  a,
  span {
    font-family: Public Sans;
    font-size: 16px;
    text-decoration: none;
    color: ${({ theme }) => theme.colors._primary.grey};
  }

  a:hover {
    text-decoration: underline;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.big}px) {
    a {
      font-size: 14px;
    }
  }

  @media (max-width: 450px) {
    a {
      font-size: 16px;
    }
  }
`

export const ColTitle = styled.h6`
  font-family: Public Sans;
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.blue[700]};

  @media (max-width: ${({ theme }) => theme.breakpoints.big}px) {
    font-size: 18px;
  }

  @media (max-width: 450px) {
    font-size: 20px;
  }
`
