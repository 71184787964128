import { purposesRO } from "./purposes"
import { options } from "./options"

const relations = {
  data: {
    purposes: purposesRO,
  },
  options: options,
}

export default relations
