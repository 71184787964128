import { TOption } from "../../@types/option"

export const locations: ReadonlyArray<TOption> = [
  { key: "all", value: "Todos" },
  { key: "flp", value: "Florianópolis" },
  { key: "rq", value: "Rancho Queimado" },
  { key: "sjs", value: "São José" },
] as const

export const locationsRO = [...locations]

export type TOlocations = typeof locationsRO
