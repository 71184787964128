import styled from "styled-components"

export const Wrapper = styled.div<{ $bg?: string }>`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  background-color: ${({ $bg }) => $bg ?? "transparent"};
`

export const Area = styled.div<{
  $direction: "row" | "column"
  $pb?: number
  $jc?: string
}>`
  position: relative;
  max-width: 1180px;
  margin: 0 48px;
  flex: 1;
  display: flex;
  flex-direction: ${({ $direction }) => $direction};
  padding-bottom: ${({ $pb }) => $pb ?? 0}px;
  ${({ $jc }) => ($jc ? `justify-content: ${$jc};` : "")};

  &:has(#smallSlider) {
    @media (max-width: 1280px) {
      max-width: calc(100% - 48px);
    }
  }

  @media (max-width: 540px) {
    margin: 0 24px;
  }
`
