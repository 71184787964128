import styled from "styled-components"

export const Page = styled.div``

export const Hero = styled.div`
  background-color: rgba(6, 48, 83, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpoints.big}px) {
    background-size: cover;
    background-position: center -60px;
  }
`

// Section

export const Section = styled.div<{ $bg?: string; $pv?: number }>`
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  padding: ${({ $pv }) => $pv ?? 140}px 0;
  display: flex;
  flex-direction: column;
  gap: 48px;
  background-color: ${({ $bg }) => $bg ?? "transparent"};

  & > button {
    margin: auto;
  }

  & > a {
    margin: auto;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    border-radius: 6px;
    width: fit-content;
    padding: 14px 24px;
    background-color: ${({ theme }) => theme.colors.blue[900]};
    border: none;
    outline: none;
    border-radius: 4px;
    backdrop-filter: blur(3px);
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
      background-color: ${({ theme }) => theme.colors.blue[700]};
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xxsm}px) {
    & > a {
      width: 100%;
      padding: 0.8rem 0.4rem;
      justify-content: center;

      span {
        font-size: 14px;
      }

      svg {
        width: 14px;
        height: 14px;
      }
    }
  }

  @media (max-width: 280px) {
    & > a svg {
      display: none;
    }
  }
`

export const Text = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.colors._primary.white};
  white-space: nowrap;
`

export const SectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    gap: 14px;
  }
`

export const SectionTitle = styled.h2`
  font-family: Bona Nova;
  font-size: 40px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.blue[600]};
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.big}px) {
    font-size: 36px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    font-size: 28px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xxsm}px) {
    text-align: left;
  }
`

export const SectionDescription = styled.span`
  text-align: center;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.grey[500]};
  max-width: 700px;

  @media (max-width: ${({ theme }) => theme.breakpoints.big}px) {
    font-size: 18px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    font-size: 16px;
    max-width: 480px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.small}px) {
    text-align: left;
    max-width: unset;
    width: 100%;
  }
`

export const Simmilars = styled.div`
  flex: 1;
  display: grid;
  gap: 50px;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: ${({ theme }) => theme.breakpoints.big}px) {
    gap: 24px;
  }

  @media (max-width: 840px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 580px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 54px;
  }
`

// Results
export const ResumeBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`

export const ResumeTitle = styled.div`
  font-family: Bona Nova;
  font-size: 40px;
  color: ${({ theme }) => theme.colors.blue[600]};
  text-align: left;

  @media (max-width: ${({ theme }) => theme.breakpoints.xxsm}px) {
    font-size: 28px;
  }
`

export const Location = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;

  span {
    font-size: 24px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.grey[700]};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xxsm}px) {
    span {
      font-size: 20px;
    }
  }
`

export const LocationStreet = styled.span``

export const LocationCity = styled.span``

export const Actions = styled.div`
  display: flex;
  gap: 12px;
`

export const Description = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10%;

  & > a {
    display: flex;
    border-radius: 12px;
    overflow: hidden;
    min-width: 420px;
    width: 100%;
    color: ${({ theme }) => theme.colors._primary.grey};
    text-decoration: none;

    img {
      width: 100%;
    }

    span,
    svg {
      display: none;
    }

    svg {
      transform: rotate(-90deg);
    }
  }

  @media (max-width: 880px) {
    & > a {
      min-width: unset;
      width: 40vw;
    }
  }

  @media (max-width: 840px) {
    flex-direction: column;
    gap: 24px;

    div {
      max-width: unset;
    }

    & > a {
      align-self: center;
      margin-bottom: 24px;
      justify-content: center;

      svg path {
        stroke: currentColor;
      }
    }

    & > a img {
      max-width: 70vw;
      width: 100%;
      margin: auto;
      display: none;
    }

    & > a span,
    & > a svg {
      display: block;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    img {
      min-width: 240px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    img {
      max-width: 100%;
      width: unset;
      min-width: unset;
    }
  }
`

export const Info = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
`

export const DescTexts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const DescText = styled.p`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.grey[500]};
  text-align: left;
  white-space: pre-line;

  @media (max-width: ${({ theme }) => theme.breakpoints.small}px) {
    text-align: justify;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xxsm}px) {
    font-size: 16px;
  }
`

export const Explore = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;
  width: min-content;

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    width: 100%;
  }
`

export const SubInfos = styled.div`
  display: flex;
  gap: 60px;

  @media (max-width: ${({ theme }) => theme.breakpoints.small}px) {
    gap: 8px;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`

export const Prices = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: stretch;
`

export const Price = styled.div`
  padding: 0.6rem 1.4rem;
  background-color: ${({ theme }) => theme.colors.gold.secondary};
  border-radius: 10px;
  width: 100%;

  span {
    font-size: 32px;
    color: ${({ theme }) => theme.colors._primary.white};
    font-weight: 600;
    white-space: nowrap;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.small}px) {
    span {
      font-size: 24px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xxsm}px) {
    span {
      font-size: 16px;
    }
  }
`

export const SubPrice = styled.div`
  display: flex;
  align-items: center;
  text-align: left;

  span {
    font-size: 18px;
    color: ${({ theme }) => theme.colors.gold.secondary};
    white-space: nowrap;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.small}px) {
    span {
      font-size: 16px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xxsm}px) {
    span {
      font-size: 14px;
    }
  }
`

export const SInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const SIValue = styled.span`
  font-family: Average Sans;
  font-weight: 500;
  font-size: 28px;
  color: ${({ theme }) => theme.colors.blue[600]};

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    font-size: 24px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.small}px) {
    font-size: 20px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xxsm}px) {
    font-size: 18px;
  }
`

export const SILabel = styled.span`
  font-family: Bona Nova;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.grey[600]};

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    font-size: 20px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.small}px) {
    font-size: 18px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xxsm}px) {
    font-size: 16px;
  }
`
