import { categoriesRO } from "./options/category"
import { locationsRO } from "./options/location"
import { orderRO } from "./options/order"
import { typesRO } from "./options/type"
import { purposesRO } from "./options/purposes"
import { buyPricesRO, rentPricesRO } from "./options/price"

export const options = {
  categories: categoriesRO,
  locations: locationsRO,
  order: orderRO,
  purposes: purposesRO,
  prices: {
    buyPrices: buyPricesRO,
    rentPrices: rentPricesRO,
  },
  types: typesRO,
}
