import Lottie from "lottie-react"

type Props = {
  data: unknown
}

const AnimationFrame = ({ data }: Props) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "grid",
        placeItems: "center",
        paddingTop: 120,
        paddingBottom: 120,
      }}
    >
      <div
        style={{
          width: 200,
        }}
      >
        <Lottie animationData={data} />
      </div>
    </div>
  )
}

export default AnimationFrame
