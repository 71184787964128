import { TOption } from "../../@types/option"

export const purposes: ReadonlyArray<TOption> = [
  { key: "all", value: "Todos" },
  { key: 1, value: "Alugar" },
  { key: 2, value: "Comprar" },
  { key: 3, value: "Alugar ou Comprar" },
] as const

export const purposesRO = [...purposes]

export type TOPurposes = typeof purposesRO
