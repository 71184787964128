import * as S from "./styled"

const Container = ({ children, direction, bg, pb, jc }: any) => {
  return (
    <S.Wrapper $bg={bg}>
      <S.Area $direction={direction ?? "column"} $pb={pb} $jc={jc}>
        {children}
      </S.Area>
    </S.Wrapper>
  )
}

export default Container
