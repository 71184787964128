import { Icon } from "../../assets/icons"
import * as S from "./styled"

type Props = {
  type: "default" | "whatsapp" | "whatsapp2"
  text: string
  fn: (v?: any) => void
  pos?: "normal"
}

const Button = ({ type, text, fn, pos }: Props) => {
  const handleClick = () => {
    if (fn) fn()
  }

  return type === "default" ? (
    <S.Box onClick={handleClick} $pos={pos}>
      <S.Text>{text}</S.Text>
    </S.Box>
  ) : type === "whatsapp" ? (
    <S.Box onClick={handleClick} $secondary={true} $pos={pos}>
      <Icon.Whatsapp />
      <S.Text>{text}</S.Text>
    </S.Box>
  ) : (
    <S.Box onClick={handleClick} $tertiary={true} $pos={pos}>
      <Icon.Whatsapp />
      <S.Text>{text}</S.Text>
    </S.Box>
  )
}

export default Button
