import { TOption } from "../../@types/option"

export const rentPrices: ReadonlyArray<TOption> = [
  { key: "all", value: "Todos" },
  { key: 1000, value: "Até R$1000,00" },
  { key: 5000, value: "Até R$5000,00" },
  { key: 10000, value: "Até R$10000,00" },
  { key: 50000, value: "Até R$50000,00" },
] as const

export const buyPrices: ReadonlyArray<TOption> = [
  { key: "all", value: "Todos" },
  { key: 50000, value: "Até R$50000,00" },
  { key: 100000, value: "Até R$100000,00" },
  { key: 250000, value: "Até R$250000,00" },
  { key: 1000000, value: "Até R$1000000,00" },
] as const

export const rentPricesRO = [...rentPrices]
export const buyPricesRO = [...buyPrices]

export type TORentPrices = typeof rentPricesRO
export type TOBuyPrices = typeof buyPricesRO
