import { Icon } from "../../assets/icons"
import * as S from "./styled"
import { TProperty } from "../../utils/@types/property"
import relations from "../../utils/relations"
import { Link } from "react-router-dom"

type ItemProps = {
  id: string
  image: string
  location: string
  purpose: string
}

const Item = ({ id, image, location, purpose }: ItemProps) => {
  return (
    <S.Item>
      <Link to={`/details/${id}`} preventScrollReset={false}>
        <S.ItemImage src={image} />
        <S.ItemData>
          <S.ItemMain>
            <S.ItemLocation>{location}</S.ItemLocation>
            <S.ItemPurpose>{purpose}</S.ItemPurpose>
          </S.ItemMain>
          <Icon.Location width={24} height={24} />
        </S.ItemData>
      </Link>
    </S.Item>
  )
}

type Props = {
  list: TProperty[]
}

const Highlights = ({ list }: Props) => {
  return (
    <S.Wrapper>
      <S.Area>
        {list.map((p, k) => (
          <Item
            key={k}
            id={p.id}
            image={p.images.main}
            location={p.location.city.name}
            purpose={relations.data.purposes[p.purpose]}
          />
        ))}
      </S.Area>
    </S.Wrapper>
  )
}

export default Highlights
