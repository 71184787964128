import * as fs from "firebase/firestore"
import * as sg from "firebase/storage"
import {
  StorageReference,
  getStorage,
  ref,
  getDownloadURL,
} from "firebase/storage"
import app from "./fb"
import { FSProperty, TProperty } from "../utils/@types/property"
import { TOption } from "../utils/@types/option"

const db = fs.getFirestore(app)
const storage = getStorage()

const getImage = async (url: string) => {
  const imgRef = ref(storage, url) as StorageReference
  const dUrl = await getDownloadURL(imgRef)

  return dUrl
}

const cities = fs.collection(db, "cities")
const properties = fs.collection(db, "properties")

type GetPropertyProps = {
  doc: fs.QueryDocumentSnapshot<fs.DocumentData, fs.DocumentData>
  cardImageOnly?: boolean
  getMap?: boolean
}

// Get

const getPropertyData = async ({
  doc,
  cardImageOnly,
  getMap,
}: GetPropertyProps) => {
  let newItem = doc.data() as FSProperty

  let images: TProperty["images"] = {
    main: "",
    all: [],
  }

  if (!!newItem.images.main) {
    images.main = await getImage(newItem.images.main)
  }

  let proms: Promise<any>[] = []

  // get images url
  if (!cardImageOnly) {
    const imagesRefs =
      (await sg.listAll(ref(storage, `/properties/${doc.id}`))) ?? []

    imagesRefs.items.forEach((img) => {
      proms.push(
        new Promise(async (resolve) => {
          const url = await getDownloadURL(img)
          images.all?.push(url)
          resolve(true)
        })
      )
    })
  }

  let mapImage = ""
  // get images url
  if (getMap) {
    const mapRef = (await sg.list(ref(storage, `/maps/${doc.id}`))).items[0]
    if (mapRef) mapImage = await getDownloadURL(mapRef)
  }

  await Promise.all(proms)

  let prop: TProperty = {
    ...newItem,
    id: doc.id,
    mapImage,
    images: images,
  }

  return { ...prop, allImages: images.all }
}

const getLocations = async () => {
  let list: TOption[] = []

  const citiesCodes = (await fs.getDocs(cities)).docs.map((doc) => doc.data())
  citiesCodes.forEach((c) => {
    list.push({ key: c.cod, value: c.name })
  })

  return (
    list.sort((a, b) =>
      a.value.toLowerCase().localeCompare(b.value.toLowerCase())
    ) ?? []
  )
}

const getSingleProperty = async (properyId: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const propRef = fs.doc(db, "properties", properyId)
      const docSnap = await fs.getDoc(propRef)

      if (docSnap.exists()) {
        const data = await getPropertyData({
          doc: docSnap,
          cardImageOnly: false,
          getMap: true,
        })
        resolve(data)
      } else {
        // eslint-disable-next-line no-throw-literal
        reject(false)
      }
    } catch (error) {
      reject(false)
    }
  })
}

const getHighlightProperties = async () => {
  const list = await fs.getDocs(
    fs.query(properties, fs.where("highlighted", "==", true))
  )

  let mainListItems: TProperty[] = []

  let proms: Promise<any>[] = []

  list.forEach((doc) => {
    proms.push(
      getPropertyData({ doc, cardImageOnly: true }).then((prop) => {
        mainListItems.push(prop)
      })
    )
  })

  await Promise.all(proms)

  return mainListItems
}

const getAllProperties = async () => {
  const properties = fs.collection(db, "properties")
  const list = await fs.getDocs(properties)

  let mainListItems: TProperty[] = []

  let proms: Promise<any>[] = []

  list.forEach((doc) => {
    proms.push(
      getPropertyData({ doc }).then((prop) => {
        mainListItems.push(prop)
      })
    )
  })

  await Promise.all(proms)

  return mainListItems
}

const getBySearch = async (config: {
  category: number | string
  purpose: number
  location: number | string
  type: number | string
  price: number
}) => {
  const properties = fs.collection(db, "properties")

  const citiesCodes = (await fs.getDocs(cities)).docs.map(
    (doc) => doc.data().cod
  )

  const qr = (() => {
    const w = {
      cat: fs.where(
        "category",
        config.category !== 0 ? "==" : "in",
        config.category !== 0 ? config.category : ["urban", "rural"]
      ),
      loc: fs.where(
        "location.city.cod",
        config.location !== 0 ? "==" : "in",
        config.location !== 0 ? config.location : citiesCodes
      ),
      typ: fs.where(
        "type",
        config.type !== 0 ? "==" : "in",
        config.type !== 0
          ? config.type
          : ["house", "flat", "farm", "land", "housefarm"]
      ),
    }

    const s = {
      cat: config.category !== 0,
      loc: config.location !== 0,
      typ: config.type !== 0,
    }

    // all
    if (s.cat && s.loc && s.typ) {
      return fs.query(properties, w.cat, w.loc, w.typ)
    } else if (s.cat && !s.loc && !s.typ) {
      // cat only
      return fs.query(properties, w.cat)
    } else if (s.cat && s.loc && !s.typ) {
      // cat & location
      return fs.query(properties, w.cat, w.loc)
    } else if (s.cat && !s.loc && s.typ) {
      // cat & type
      return fs.query(properties, w.cat, w.typ)
    } else if (!s.cat && s.loc && s.typ) {
      // location & type
      return fs.query(properties, w.loc, w.typ)
    } else if (!s.cat && s.loc && !s.typ) {
      // location only
      return fs.query(properties, w.loc)
    } else if (!s.cat && !s.loc && s.typ) {
      // type only
      return fs.query(properties, w.typ)
    }

    return fs.query(properties)
  })()

  const docs = (await fs.getDocs(qr)).docs.map((doc) => doc)

  let list: fs.QueryDocumentSnapshot<fs.DocumentData, fs.DocumentData>[] = []

  let items: TProperty[] = []

  if (config.price && !Number.isNaN(+config.price)) {
    list = docs.filter(
      (doc) => doc.data().price <= +(config.price as any) * 100
    )
  } else {
    list = docs
  }

  let proms: Promise<any>[] = []

  list.forEach((doc) => {
    proms.push(
      getPropertyData({ doc, cardImageOnly: true }).then((prop) => {
        items.push(prop)
      })
    )
  })

  await Promise.all(proms)

  return items
}

const getRecents = async () => {
  const properties = fs.collection(db, "properties")

  const list = await fs.getDocs(fs.query(properties))

  let items: TProperty[] = []

  let proms: Promise<any>[] = []

  list.docs.forEach((doc) => {
    proms.push(
      getPropertyData({ doc, cardImageOnly: true }).then((prop) => {
        items.push(prop)
      })
    )
  })

  await Promise.all(proms)

  const ordered = items.sort((a, b) => {
    return a.date.seconds > b.date.seconds ? -1 : 1
  })

  return ordered
}

const getSimmilars = async (cityCode: string, type: string, id: string) => {
  const properties = fs.collection(db, "properties")

  const list = await fs.getDocs(
    fs.query(
      properties,
      fs.where("location.city.cod", "==", cityCode),
      fs.where("type", "==", type)
    )
  )

  let items: TProperty[] = []

  let proms: Promise<any>[] = []

  list.docs
    .filter((doc) => doc.id !== id)
    .forEach((doc) => {
      proms.push(
        getPropertyData({ doc, cardImageOnly: true }).then((prop) => {
          items.push(prop)
        })
      )
    })

  await Promise.all(proms)

  return items
}

// Create

const addProperty = async (data: Partial<TProperty>) => {
  await fs.addDoc(properties, data)
}

const Api = {
  get: {
    all: getAllProperties,
    highlights: getHighlightProperties,
    locations: getLocations,
    property: getSingleProperty,
    recents: getRecents,
    search: getBySearch,
    simmilars: getSimmilars,
  },
  // create
  add: {
    property: addProperty,
  },
}

export default Api
