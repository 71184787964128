import { useEffect, useRef, useState } from "react"
import { Icon } from "../../assets/icons"
import * as S from "./styled"
import { TOption } from "../../utils/@types/option"

type Props = {
  selected: TOption
  options: TOption[]
  handleSelect: (option: TOption) => void
}

const Select = ({ selected, options, handleSelect }: Props) => {
  const mainRef = useRef<HTMLDivElement>(null)
  const dropRef = useRef<HTMLDivElement>(null)
  const [showing, setShowing] = useState(false)

  useEffect(() => {
    const fn = (e: any) => {
      if (
        !mainRef.current?.contains(e.target) &&
        !dropRef.current?.contains(e.target.parentElement) &&
        showing
      ) {
        setShowing(false)
        document.removeEventListener("mousedown", fn)
      }
    }

    document.addEventListener("mousedown", fn)
  })

  const toggleDropdown = () => {
    setShowing(!showing)
  }

  const pickOption = (option: TOption) => {
    handleSelect(option)
    toggleDropdown()
  }

  return (
    <S.Wrapper>
      <S.Area>
        <S.MainArea ref={mainRef} $showing={showing} onClick={toggleDropdown}>
          <span>{selected.value}</span>
          <Icon.Dropdown />
        </S.MainArea>
        <S.Dropdown ref={dropRef} $showing={showing}>
          {options.map((opt) => (
            <S.Item key={opt.key} onClick={() => pickOption(opt)}>
              <span>{opt.value}</span>
            </S.Item>
          ))}
        </S.Dropdown>
      </S.Area>
    </S.Wrapper>
  )
}

export default Select
