import * as S from "./styled"
import { TOption } from "../../utils/@types/option"
import relations from "../../utils/relations"
import Select from "../Select"

type Props = {
  order: TOption
  setOrder: (order: TOption) => void
}

const OrderBy = ({ order, setOrder }: Props) => {

  return (
    <S.Wrapper>
      <S.Area>
        <S.MainArea>
          <span>Ordenar por:</span>
          <Select
            selected={order}
            handleSelect={setOrder}
            options={relations.options.order}
          />
        </S.MainArea>
      </S.Area>
    </S.Wrapper>
  )
}

export default OrderBy
