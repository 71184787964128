import { TOption } from "../../@types/option"

export const order: ReadonlyArray<TOption> = [
  { key: "a+", value: "Mais espaço" },
  { key: "a-", value: "Menos espaço" },
  { key: "m+", value: "Mais caro" },
  { key: "m-", value: "Mais em conta" },
] as const

export const orderRO = [...order]

export type TOOrder = typeof orderRO
