import * as S from "./styled"
import Container from "../../components/Container"
import Header from "../../components/Header"
import SearchBox from "../../components/SearchBox"
import Card from "../../components/Card"
import Button from "../../components/Button"
import BestPlace from "../../components/BestPlace"
import Footer from "../../components/Footer"
import { useCallback, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import Api from "../../api"
import { TProperty } from "../../utils/@types/property"
import relations from "../../utils/relations"
import { consts } from "../../utils/consts"
import { animations } from "../../assets/animations"
import AnimationFrame from "../../components/AnimationFrame"

const Search = () => {
  const [params] = useSearchParams()

  const [searched, setSearched] = useState(false)
  const [loading, setLoading] = useState(false)

  const [results, setResults] = useState<TProperty[]>([])
  const [page, setPage] = useState(1)

  const [order, setOrder] = useState(relations.options.order[0])
  const [purpose, setPurpose] = useState<string | number>("all")

  const getParamsConfig = useCallback(() => {
    const config = {
      category: params.get("category") ?? undefined,
      purpose: params.get("purpose") ?? undefined,
      location: params.get("location") ?? undefined,
      type: params.get("type") ?? undefined,
      price: params.get("price") ?? undefined,
    }

    return config
  }, [params])

  const changeUrl = (searchConfig: any) => {
    let str = "/search?"

    if (searchConfig.category !== undefined)
      str += `category=${searchConfig.category}`

    // if (searchConfig.purpose !== undefined)
    //   str += `${str.length > 8 ? "&" : ""}purpose=${String(
    //     searchConfig.purpose
    //   )}`

    if (searchConfig.location)
      str += `${str.length > 8 ? "&" : ""}location=${searchConfig.location}`

    if (searchConfig.type)
      str += `${str.length > 8 ? "&" : ""}type=${searchConfig.type}`

    if (searchConfig.price !== undefined)
      str += `${str.length > 8 ? "&" : ""}price=${String(searchConfig.price)}`

    window.history.replaceState(null, document.title, str)
  }

  const getFilters = (config: any) => {
    let filter: any = {
      category: 0,
      // purpose: 0,
      location: 0,
      type: 0,
      price: 0,
    }

    // category
    if (config.category !== undefined && config.category !== "all") {
      filter.category = config.category
    }

    // purpose
    // if (
    //   config.purpose !== undefined &&
    //   !Number.isNaN(config.purpose) &&
    //   config.purpose !== "all"
    // ) {
    //   filter.purpose = Number(config.purpose)
    // }

    // location
    if (config.location !== undefined && config.location !== "all") {
      filter.location = config.location
    }

    // type
    if (config.type !== undefined && config.type !== "all") {
      filter.type = config.type
    }

    // price
    if (
      config.price !== undefined &&
      !Number.isNaN(config.price) &&
      config.price !== "all"
    ) {
      filter.price = config.price
    }

    return filter
  }

  const handleSearch = useCallback(
    async (searchConfig?: any) => {
      if (!searched) setSearched(true)

      if (searchConfig) changeUrl(searchConfig)
      const config = getFilters(searchConfig ?? getParamsConfig())

      setLoading(true)
      const list = await Api.get.search(config)
      setLoading(false)

      setResults(list)
    },
    [searched, getParamsConfig]
  )

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" })

    const paramsData = getParamsConfig()
    if (
      paramsData.category ||
      paramsData.purpose ||
      paramsData.location ||
      paramsData.type ||
      paramsData.price
    )
      handleSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <S.Page>
      <S.Hero>
        <Container direction={"column"}>
          <Header />
        </Container>
      </S.Hero>

      <Container>
        <S.Section>
          <S.SectionHeader>
            <S.SectionTitle>SUA BUSCA POR IMÓVEIS</S.SectionTitle>
          </S.SectionHeader>
        </S.Section>
        <SearchBox
          showSubFilter={true}
          callback={(config) => handleSearch(config)}
          order={order}
          setOrder={setOrder}
          purpose={purpose}
          setPurpose={setPurpose}
        />
      </Container>

      <Container>
        {loading ? (
          <AnimationFrame data={animations.loading} />
        ) : (
          <S.Section $pv={60}>
            <S.Results>
              {results
                .sort((a, b) => {
                  let pos = 0

                  if (order.key === "a+") {
                    pos = a.area > b.area ? -1 : 1
                  }

                  if (order.key === "a-") {
                    pos = a.area < b.area ? -1 : 1
                  }

                  if (order.key === "m+") {
                    pos = a.price > b.price ? -1 : 1
                  }

                  if (order.key === "m-") {
                    pos = a.price < b.price ? -1 : 1
                  }

                  return pos
                })
                .slice(0, page * consts.perPage)
                .filter((p) =>
                  purpose === "all" ? true : p.purpose === purpose
                )
                .map((prop, k) => (
                  <Card key={k} data={prop} />
                ))}
            </S.Results>
            {results.filter((p) =>
              purpose === "all" ? true : p.purpose === purpose
            ).length > 0 &&
              results.filter((p) =>
                purpose === "all" ? true : p.purpose === purpose
              ).length -
                page * consts.perPage >
                0 && (
                <Button
                  type="default"
                  text={"Mostrar mais"}
                  fn={() => {
                    setPage(page + 1)
                  }}
                />
              )}
            {results.filter((p) =>
              purpose === "all" ? true : p.purpose === purpose
            ).length === 0 &&
              searched && <>Oops, não encontramos imóveis com esse filtro</>}
          </S.Section>
        )}
      </Container>

      <BestPlace />
      <Footer />
    </S.Page>
  )
}

export default Search
