import React from "react"
import { ThemeProvider } from "styled-components"
import { theme } from "./styled"
import Router from "./routes"

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router />
    </ThemeProvider>
  )
}

export default App
