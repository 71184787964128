import { TOption } from "../../@types/option"

export const types: ReadonlyArray<TOption> = [
  { key: "all", value: "Todos" },
  { key: "flat", value: "Apartamento" },
  { key: "house", value: "Casa" },
  { key: "farms", value: "Chácaras e Fazendas" },
  { key: "land", value: "Terrenos" },
] as const

export const typesRO = [...types]

export type TOtypes = typeof typesRO
