import styled from "styled-components"

export const Section = styled.header`
  display: flex;
  flex-direction: column;
  padding-top: 28px;
  position: sticky;
  z-index: 200;
  width: 100%;
  max-height: 200px;
`

export const Top = styled.div`
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors._primary.white};

  @media (max-width: ${({ theme }) => theme.breakpoints.xsmall}px) {
    display: none;
  }
`

export const Contact = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    gap: 8px;

    svg {
      width: 18px;
    }
  }
`

export const ContactTxt = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors._primary.white};

  span,
  a span {
    font-family: "Bona Nova";
  }

  a {
    text-decoration: none;
    color: currentColor;

    &:hover {
      text-decoration: underline;
    }
  }

  span:nth-child(1) {
    font-weight: 700;
  }

  span:nth-child(2) {
    font-weight: 400;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.small}px) {
    & > span:nth-child(1) {
      font-weight: 700;
      display: none;
    }
  }
`

export const Socials = styled.div`
  display: flex;
  align-items: center;
  gap: 42px;
  color: ${({ theme }) => theme.colors._primary.white};

  a {
    color: currentColor;
  }

  svg {
    width: 14px;
    height: 14px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    gap: 18px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.small}px) {
    a {
      display: none;
    }
  }
`

export const PhoneSection = styled.div`
  a {
    display: flex;
    align-items: center;
    gap: 12px;
    text-decoration: none;
    font-size: 14px;
    padding-left: 18px;
    color: ${({ theme }) => theme.colors._primary.white};
    border-left: 1px solid ${({ theme }) => theme.colors._primary.white};

    svg {
      width: 20px;
      height: 20px;
    }

    span {
      font-size: 12px;
    }

    &:hover span {
      text-decoration: underline;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.small}px) {
      padding: 0;
      border-left: none;
    }
  }
`

export const Main = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 24px 0;

  img {
    height: 76px;
    width: auto;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.big}px) {
    img {
      height: 48px;
    }
  }
`

export const Nav = styled.nav<{ $showing: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  transition: ${({ $showing }) =>
    $showing ? "transform 0.3s, box-shadow 0.3s" : ""};

  @media (max-width: ${({ theme }) => theme.breakpoints.big}px) {
    gap: 8px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    position: ${({ $showing }) => ($showing ? "fixed" : "absolute")};
    background-color: ${({ theme }) => theme.colors.blue[800]};
    right: -48px;
    transform: translate(${({ $showing }) => ($showing ? "-48px" : "100%")}, 0);
    height: calc(100vh + ${window.scrollY}px);
    top: 0;
    padding: 24px;
    z-index: 10;

    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    min-width: 240px;
    width: fit-content;
    max-width: 60vw;

    box-shadow: ${({ $showing }) =>
      $showing
        ? "-22px 4px 20px 20px rgba(24, 46, 79, 1)"
        : "0px 0px 0px 0px rgba(24, 46, 79, 0)"};
  }
`

export const Burguer = styled.div<{ $showing: boolean }>`
  padding: 4px;
  cursor: pointer;
  position: absolute;
  top: ${({ $showing }) => ($showing ? 24 + window.scrollY : 106)}px;
  left: ${({ $showing }) => ($showing ? 24 : -70)}px;
  transition: ${({ $showing }) => ($showing ? "left 0.3s, top 0.6s" : "")};

  @media (min-width: ${({ theme }) => theme.breakpoints.medium + 1}px) {
    visibility: none;
    display: none;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xsmall}px) {
    top: ${({ $showing }) => ($showing ? 54 + window.scrollY : 54)}px;
    left: ${({ $showing }) => ($showing ? 24 : -70)}px;
  }
`

export const MenuItem = styled.div<{
  $hasBorder?: boolean
  $avoidCap?: boolean
}>`
  a {
    display: block;
    padding: 12px 18px;
    border: 1px solid
      ${({ $hasBorder, theme }) =>
        $hasBorder ? theme.colors.gold.secondary : "transparent"};

    text-decoration: none;
    text-transform: ${({ $avoidCap }) => ($avoidCap ? "unset" : "capitalize")};
    border-radius: 4px;
    color: ${({ $hasBorder, theme }) =>
      $hasBorder ? theme.colors.gold.primary : theme.colors._primary.white};
    cursor: pointer;
    white-space: nowrap;
    font-size: 14px;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
      ${({ $hasBorder, theme }) =>
        $hasBorder ? `background-color: ${theme.colors.gold.secondary};` : ""}
      ${({ $hasBorder, theme }) =>
        $hasBorder ? `color: ${theme.colors._primary.white};` : ""}
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.big}px) {
      padding: 10px 14px;
      font-size: 13px;
    }
  }
`
