import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import Home from "../pages/Home"
import Search from "../pages/Search"
import Details from "../pages/Details"

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={"/"}>
          <Route path={""} element={<Home />} />
          <Route path={"search"} element={<Search />} />
          <Route path={"search/*"} element={<Search />} />
          <Route path={"details/:propertyId"} element={<Details />} />
          <Route
            path={"*"}
            element={<Navigate to={"search"} relative="path" />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Router
