import { Link } from "react-router-dom"
import * as S from "./styled"

type Props = {
  size?: "big" | "small"
  icon: JSX.Element
  link?: string
  fn?: () => void
}

const SocialBtn = ({ size, icon, link, fn }: Props) => {
  return (
    <S.Btn $size={size ?? "small"}>
      {link ? (
        <Link
          to={link ?? ""}
          target="_blank"
          onClick={(e) => {
            if (fn) {
              e.preventDefault()
              fn()
            }
          }}
        >
          {icon}
        </Link>
      ) : (
        <div onClick={fn}>{icon}</div>
      )}
    </S.Btn>
  )
}

export default SocialBtn
