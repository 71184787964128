import styled from "styled-components"

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 60px;

  @media (max-width: ${({ theme }) => theme.breakpoints.small}px) {
    margin-top: -100px;
  }
`

export const Area = styled.div<{ $normal?: boolean }>`
  position: ${({ $normal }) => ($normal ? "relative" : "absolute")};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors._primary.white};
  box-shadow: 0 4px 92px 0 rgba(24, 46, 79, 0.3);
  transform: translateY(${({ $normal }) => ($normal ? 0 : -50)}%);
  padding: 32px 62px;
  border-radius: 8px;
  z-index: 3;

  button {
    border: none;
    outline: none;
    background: ${({ theme }) => theme.colors.gold.primary};
    color: ${({ theme }) => theme.colors._primary.white};
    padding: 12px 26px;
    border-radius: 4px;
    cursor: pointer;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.big}px) {
    padding: 32px 28px;
  }

  // @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
  @media (max-width: 840px) {
    padding-bottom: 40px;
    gap: 0px;

    button {
      padding: 12px 28px;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    flex-wrap: wrap;
    gap: 24px 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.small}px) {
    flex-direction: column;
    gap: 24px;
    position: relative;
    transform: unset;
  }
`

export const Box = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 58px;
  padding-right: 18px;
  position: relative;
  gap: 16px;
  border-right: 1px solid ${({ theme }) => theme.colors.grey[100]};

  & > span {
    font-size: 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.blue[700]};
  }

  &:nth-child(1) {
    padding-left: 0px;
  }

  &:nth-child(4) {
    margin-right: 58px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.big}px) {
    gap: 8px;

    & > span {
      font-size: 14px;
    }

    &:nth-child(4) {
      margin-right: 0px;
    }
  }

  @media (max-width: 840px) {
    padding-left: 40px;
    margin-right: 0px;

    &:nth-child(4) {
      border: none;
    }
  }

  @media (max-width: 780px) {
    padding-left: 30px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    min-width: calc(50% - 48px);

    padding-left: 0;

    &:nth-child(2) {
      border: none;
      padding-left: 24px;
    }

    &:nth-child(4) {
      padding-left: 24px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.small}px) {
    min-width: unset;
    width: 100%;
    padding-left: 0;
    border: none;

    & > span {
      display: block;
      flex: 1;
      min-width: 160px;
    }

    padding-right: 0;

    &:nth-child(2),
    &:nth-child(4) {
      padding: 0;
    }
  }
`

export const Divider = styled.div`
  align-self: stretch;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.grey[100]};

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    display: none;
  }
`

// SubFilters

export const SubFilters = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.breakpoints.small}px) {
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
  }
`

export const Categories = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`

export const Cat = styled.div<{ $active: boolean }>`
  cursor: pointer;

  span {
    font-size: 16px;
    font-weight: ${({ $active }) => ($active ? 600 : 400)};
    color: ${({ theme }) => theme.colors.grey[900]};
  }
`
