const formatMillions = (n: number) => {
  const qntMll = +(n / 1000000).toFixed(1)
  return qntMll
}

export const formatArea = (qnt: number) => {
  let v = String(qnt).replace(/\D/g, "")

  const isMillion = !Number.isNaN(v) && +v >= 10000000

  if (isMillion) v = String(formatMillions(+v))

  v = v = String(+v).padStart(3, "0")
  v = v.replace(/\B(?=(\d{3})+(?!\d))/g, ".")

  return (
    <span style={{ whiteSpace: "nowrap" }}>
      {`${v}${isMillion ? ` mi ` : ""}m`}
      <sup>2</sup>
    </span>
  )
}
