import styled from "styled-components"
import { Image } from "../../assets/images"

export const Section = styled.section`
  background-image: url("${Image.HeroBlue}");
  background-size: 115vw;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpoints.big}px) {
    background-size: cover;
    background-position: center -60px;
  }
`

export const Content = styled.div<{ $bg?: string; $pv?: number }>`
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  padding: 152px 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 48px;
  background-color: ${({ $bg }) => $bg ?? "transparent"};

  a {
    margin: auto;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    border-radius: 6px;
    width: fit-content;
    padding: 12px 24px;
    background-color: rgba(252, 252, 252, 0.05);
    border: 1px solid ${({ theme }) => theme.colors._primary.white};
    outline: none;
    border-radius: 4px;
    backdrop-filter: blur(3px);
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
      background-color: rgba(252, 252, 252, 0.2);
    }
  }
`

export const Text = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.colors._primary.white};
  white-space: nowrap;
`

export const SectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    gap: 14px;
  }
`

export const SectionTitle = styled.h2`
  font-family: Bona Nova;
  font-size: 40px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors._primary.white};
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.big}px) {
    font-size: 36px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    font-size: 28px;
  }
`

export const SectionDescription = styled.span`
  text-align: center;
  font-size: 20px;
  color: ${({ theme }) => theme.colors._primary.white};
  max-width: 700px;

  @media (max-width: ${({ theme }) => theme.breakpoints.big}px) {
    font-size: 18px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    font-size: 16px;
    max-width: 480px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.small}px) {
    max-width: 70vw;
  }
`
