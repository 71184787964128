import styled from "styled-components"

export const Box = styled.button<{ $secondary?: boolean; $tertiary?: boolean, $pos?: "normal" }>`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-radius: 6px;
  width: fit-content;
  padding: 12px 24px;
  background-color: ${({ $secondary, $tertiary, theme }) =>
    $secondary
      ? "rgba(252, 252, 252, 0.05)"
      : $tertiary
      ? theme.colors.blue[900]
      : theme.colors.gold.secondary};
  border: 1px solid
    ${({ $secondary, theme }) =>
      $secondary ? theme.colors._primary.white : "transparent"};
  outline: none;
  border-radius: 4px;
  backdrop-filter: blur(3px);
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${({ $secondary, $tertiary, theme }) =>
      $secondary
        ? "rgba(252, 252, 252, 0.2)"
        : $tertiary
        ? theme.colors.blue[700]
        : theme.colors.gold.primary};
  }
`

export const Text = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.colors._primary.white};
  white-space: nowrap;
`
