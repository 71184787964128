import { DefaultTheme } from "styled-components/dist/types"

export const theme: DefaultTheme = {
  colors: {
    _primary: {
      blue: "#4A60A1",
      white: "#FCFCFC",
      grey: "#6D737A",
    },
    gold: {
      primary: "#D7AE5B",
      secondary: "#987225",
    },
    grey: {
      900: "#1B1D1F",
      700: "#363A3D",
      600: "#52565C",
      500: "#6D737A",
      400: "#889099",
      300: "#A0A6AD",
      200: "#B8BCC2",
      100: "#CFD3D6",
      50: "#E7E9EB",
      0: "#FCFCFC",
    },
    blue: {
      900: "#0F1320",
      800: "#182E4F",
      700: "#2C3A61",
      600: "#3B4D81",
      500: "#4A60A1",
      400: "#6E80B4",
      300: "#92A0C7",
      200: "#B7BFD9",
      100: "#DBDFEC",
      50: "#EDEFF6",
    },
  },
  breakpoints: {
    xbig: 1180,
    big: 1080,
    medium: 720,
    small: 580,
    xsmall: 460,
    xxsm: 360,
  },
}
