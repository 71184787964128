import { useState } from "react"
import { Icon } from "../../assets/icons"
import { Image } from "../../assets/images"
import * as S from "./styled"
import { Link } from "react-router-dom"
import { consts } from "../../utils/consts"

const Header = () => {
  const [showingNav, setShowingNav] = useState(false)

  const toggleNav = () => {
    setShowingNav(!showingNav)
  }

  return (
    <S.Section>
      <S.Top>
        <S.Contact>
          <Icon.MailOpen />
          <S.ContactTxt>
            <span>fale conosco:</span>
            <a href={"mailto:contato@lavecci.com.br"}>
              <span> contato@lavecci.com.br</span>
            </a>
          </S.ContactTxt>
        </S.Contact>
        <S.Socials>
          <Link
            to={"https://www.instagram.com/lavecci_imoveis/"}
            target="_blank"
          >
            <Icon.Instagram />
          </Link>
          <S.PhoneSection>
            <Link to={"tel:+5548999733487"}>
              <Icon.Phone />
              <span>(48) 99973.3487</span>
            </Link>
          </S.PhoneSection>
        </S.Socials>
      </S.Top>
      <S.Main>
        <Link to={"/"}>
          <img src={Image.LogoHeader} alt={""} />
        </Link>
        <S.Nav $showing={showingNav}>
          <S.Burguer $showing={showingNav} onClick={toggleNav}>
            <Icon.Burguer width={16} height={16} />
          </S.Burguer>
          <S.MenuItem>
            <Link to={"/"}>INÍCIO</Link>
          </S.MenuItem>
          <S.MenuItem>
            <Link to={"/search"}>IMÓVEIS</Link>
          </S.MenuItem>
          <S.MenuItem>
            <Link
              to={`${consts.whatsapp}Olá! Gostaria de lhe perguntar..`}
              target="_blank"
            >
              CONTATO
            </Link>
          </S.MenuItem>
          <S.MenuItem $hasBorder={true} $avoidCap={true}>
            <Link
              to={`${consts.whatsapp}Olá! Quero anunciar meu imóvel`}
              target="_blank"
            >
              Quero anunciar
            </Link>
          </S.MenuItem>
        </S.Nav>
      </S.Main>
    </S.Section>
  )
}

export default Header
