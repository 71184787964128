import { TOption } from "../../@types/option"

export const categories: ReadonlyArray<TOption> = [
  { key: "all", value: "Todos" },
  { key: "urban", value: "Imóveis urbanos" },
  { key: "rural", value: "Imóveis rurais" },
] as const

export const categoriesRO = [...categories]

export type TOcategories = typeof categoriesRO
