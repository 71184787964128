import { useEffect, useState } from "react"
import relations from "../../utils/relations"
import OrderBy from "../OrderBy"
import Select from "../Select"
import * as S from "./styled"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import Button from "../Button"
import { TOption } from "../../utils/@types/option"
import Api from "../../api"

type Props = {
  normal?: boolean
  showSubFilter?: boolean
  callback?: (searchConfig: any) => void
  order?: TOption
  setOrder?: (order: TOption) => void
  purpose?: number | string
  setPurpose?: (p: number | string) => void
}

const SearchBox = ({
  normal,
  showSubFilter,
  callback,
  order,
  setOrder,
  purpose,
  setPurpose,
}: Props) => {
  const [params] = useSearchParams()

  const location = useLocation()
  const navigate = useNavigate()

  const [locations, setLocations] = useState([relations.options.locations[0]])

  const [searchConfig, setSearchConfig] = useState({
    category: relations.options.categories[0],
    purpose: relations.options.purposes[0],
    location: locations[0],
    type: relations.options.types[0],
    price: relations.options.prices.rentPrices[0],
  })

  const getParamsFromConfig = () => {
    let str = "?"
    str += `category=${searchConfig.category.key}&`
    str += `purpose=${searchConfig.purpose.key}&`
    str += `location=${searchConfig.location.key}&`
    str += `type=${searchConfig.type.key}&`
    str += `price=${searchConfig.price.key}`

    return str
  }

  const getParamsConfig = () => {
    const config = {
      category: params.get("category") ?? undefined,
      purpose: params.get("purpose") ?? undefined,
      location: params.get("location") ?? undefined,
      type: params.get("type") ?? undefined,
      price: params.get("price") ?? undefined,
    }

    return config
  }

  const getParams = () => {
    const config = getParamsConfig()

    setSearchConfig({
      category:
        config.category === undefined
          ? relations.options.categories[0]
          : (relations.options.categories.find(
              (o) => o.key === config.category
            ) as TOption),
      purpose:
        config.purpose === undefined
          ? relations.options.purposes[0]
          : (relations.options.purposes.find(
              (o) => o.key === Number(config.purpose)
            ) as TOption) ?? relations.options.purposes[0],
      location:
        config.location === undefined
          ? relations.options.locations[0]
          : (relations.options.locations.find(
              (o) => o.key === config.location
            ) as TOption),
      type:
        config.type === undefined
          ? relations.options.types[0]
          : (relations.options.types.find(
              (o) => o.key === config.type
            ) as TOption),
      price:
        config.purpose === "1"
          ? relations.options.prices.rentPrices[0]
          : relations.options.prices.buyPrices[0],
    })

    if (
      config.purpose &&
      !!relations.options.purposes.find(
        (p) => p.key === +(config.purpose as any)
      )
    ) {
      setPurpose && setPurpose(+(config.purpose as any) ?? "all")
    }
  }

  const handleSearch = () => {
    if (location.pathname.includes("search") && !!callback) {
      callback({
        category: searchConfig.category.key,
        purpose: searchConfig.purpose.key,
        location: searchConfig.location.key,
        type: searchConfig.type.key,
        price: searchConfig.price.key,
      })
    } else {
      navigate(`/search${getParamsFromConfig()}`)
    }
  }

  const pickPurpose = (p: "all" | 1 | 2) => {
    setPurpose && setPurpose(p)
  }

  useEffect(() => {
    Api.get.locations().then((list) => {
      setLocations(list)
    })

    getParams()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <S.Wrapper>
      <S.Area $normal={normal ?? showSubFilter}>
        <S.Box>
          <span>Categoria</span>
          <Select
            selected={searchConfig.category}
            options={relations.options.categories}
            handleSelect={(option) =>
              setSearchConfig({ ...searchConfig, category: option })
            }
          />
        </S.Box>
        <S.Box>
          <span>Cidade / Região</span>
          <Select
            selected={searchConfig.location ?? locations[0]}
            options={locations}
            handleSelect={(option) =>
              setSearchConfig({ ...searchConfig, location: option })
            }
          />
        </S.Box>
        <S.Box>
          <span>Tipo de Imóvel</span>
          <Select
            selected={searchConfig.type}
            options={relations.options.types}
            handleSelect={(option) =>
              setSearchConfig({ ...searchConfig, type: option })
            }
          />
        </S.Box>
        <S.Box>
          <span>Faixa de preço</span>
          <Select
            selected={searchConfig.price}
            options={
              purpose === 1
                ? relations.options.prices.rentPrices
                : relations.options.prices.buyPrices
            }
            handleSelect={(option) =>
              setSearchConfig({ ...searchConfig, price: option })
            }
          />
        </S.Box>
        <Button type="default" fn={handleSearch} text="Buscar" />
      </S.Area>
      {showSubFilter && order && setOrder && (
        <S.SubFilters>
          <OrderBy order={order} setOrder={setOrder} />
          <S.Categories>
            <S.Cat
              $active={purpose === "all"}
              onClick={() => pickPurpose("all")}
            >
              <span>Todas</span>
            </S.Cat>
            <S.Cat $active={purpose === 2} onClick={() => pickPurpose(2)}>
              <span>Comprar</span>
            </S.Cat>
            <S.Cat $active={purpose === 1} onClick={() => pickPurpose(1)}>
              <span>Alugar</span>
            </S.Cat>
          </S.Categories>
        </S.SubFilters>
      )}
    </S.Wrapper>
  )
}

export default SearchBox
